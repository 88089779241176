import React from 'react'

import Layout from '../../components/layout'
import Container from '../../components/container'

import Photo from '../../images/contact/PG-05.jpg'

import './style.css'

const ContactPage = () => (
  <Layout>
    <Container>
      <section className="contact-page">
        <section className="contact-text">
        
          <article>
            <h6>ADDRESS</h6>

            <article>
              <p>AO Architectural Design</p>
              <p>P.O.Box 2272,</p>
              <p>Monterey, CA 93942</p>
            </article>

            <article>
              <p>9699 Blue Larkspur Ln, Suite 102</p>
              <p>Monterey, CA 93940</p>
            </article>
          </article>

          <article>
            <h6>PHONE</h6>

            <article>
              <p>831.682.1331</p>
            </article>
          </article>

          <article>
            <h6>EMAIL</h6>
            
            <article>
              <p>anatoly@aoarchdesign.com</p>
            </article>
          </article>
        </section>

        <section className="contact-image">
          <img src={Photo} alt="Oceanfront View"></img>
        </section>
      </section>
    </Container>
  </Layout>
)

export default ContactPage
